<script lang="ts" setup>
const props = defineProps<{
  large?: boolean;
  readonly?: boolean;
}>();
const value = defineModel<number>('value', {
  required: true,
});

const readonlyCount = computed(() => Math.round(value.value));
</script>

<template>
  <div class="baseRating" :class="{ baseRating_large: large }">
    <template v-if="props.readonly">
      <span v-for="item in readonlyCount" :key="item" class="baseRating__item">
        <SvgoSimpleStar filled class="baseRating__star" />
      </span>
    </template>
    <template v-else>
      <button
        v-for="item in 5"
        :key="item"
        @click="value = item"
        class="baseRating__item baseRating__item_button"
        :class="{ baseRating__item_outlined: item > value }"
        type="button"
      >
        <SvgoSimpleStar filled class="baseRating__star" />
      </button>
    </template>
  </div>
</template>

<style lang="scss">
.baseRating {
  display: flex;
  gap: 2px;

  &_large &__item {
    width: 20px;
    height: 20px;
  }

  &__item {
    width: 14px;
    height: 15px;
    display: block;
    position: relative;
    color: #121212;
    transition: color 0.3s ease;

    &_button {
      cursor: pointer;
      background: none;
      border: none;
      padding: 0;
    }

    &_outlined {
      color: transparent;
    }
  }

  &__star {
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
